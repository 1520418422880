import * as actionTypes from "../constants/action-types";
import * as script from '../actions/script.js'

const initialState = {
  doseQuantity: [],
  doseFrequancy: [],
  doseOtherInfo: [],
  scriptLoaded: false,
  scriptId: null,
  error: null,
  patientId: null,
  practitionerId: null,
  orderNumber: null,
  scriptList: [],
  scriptDataLoaded: false,
  patientScriptList: [],
  patientOrderList: [],
  currentScriptId: null,
  scriptDetails: [],
  sentScript: "",
  practitionerOrderList: [],
  savedVariants: [],
  editScript: false,
  editScriptDetails: false,
  scriptName: null,
  scriptOrderDetails: null,
  scriptOrderLoaded: false,
  tenantDiscount: 0,
  loadedScript: false,
  practiceSelfOrder: null,
  practitionerRecommList: [],
  scriptsTotalPage: 0,
  otherRecommendations: "",
  updateScriptReq: false,
  patientRecommedList: [],
  newScriptDetails: [],
  cartId: null,
  scriptTableLoad: false,
  scriptUpdate: false,
  recommendedPractitionerList: [],
  recommendedPatientList: [],
  hcpIngredients: [],
  savedHcpIngredients: [],
  scriptLoad: false
};
// script_variants
const clearScriptDetail = (state) => {
  return Object.assign({}, state, { ...initialState });
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_DOSAGE:
      return Object.assign({}, state, {
        doseFrequancy: action.dose_frequancy,
        doseOtherInfo: action.dose_other_info,
        doseQuantity: action.dose_qunatity,
        scriptLoaded: true,
      });

    case actionTypes.CREATE_SCRIPT:
      return Object.assign({}, state, {
        scriptId: action.scriptId,
        orderNumber: action.orderNumber,
        tenantDiscount: action.tenantDiscount,
      });

    case actionTypes.SCRIPT_DETAILS:

      console.log(action.payload, "---------------payload");
      return Object.assign({}, state, {
        scriptDetails: action.payload,
        hcpIngredients: action.payload.hcp_ingredients,
        scriptId: action.payload.id,
        savedVariants: action.payload.script_variants,
        scriptDataLoaded: true,
        tenantDiscount: action.tenantDiscount,
        cartId: action.payload.script_cart_id,
        scriptLoad: true
      });

    case actionTypes.PRACTITIONER_SCRIPTS:
      return Object.assign({}, state, {
        scriptList: action.payload.scripts,
        scriptsTotalPage: action.payload.total_pages,
        loaded: true,
      });

    case actionTypes.PRACTITIONER_RECOMMENDATION_LIST_INIT:
      return Object.assign({}, state, {
        scriptTableLoad: true
      })

    case actionTypes.PRACTITIONER_RECOMMENDATION_LIST:
      return Object.assign({}, state, {
        practitionerRecommList: action.payload.scripts,
        scriptsTotalPage: action.payload.total_pages,
        loaded: true,
        scriptTableLoad: false
      });

    case actionTypes.PRACTITIONER_RECOMMENDATION_LIST_FAILURE:
      return Object.assign({}, state, {
        scriptTableLoad: true
      })

    case actionTypes.PATIENT_RECOMMENDATION_LIST_INIT:
      return Object.assign({}, state, {
        scriptTableLoad: true
      })
    case actionTypes.PATIENT_RECOMMENDATION_LIST:
      return Object.assign({}, state, {
        patientRecommedList: action.payload.scripts,
        scriptsTotalPage: action.payload.total_pages,
        scriptTableLoad: false
      });
    case actionTypes.PATIENT_RECOMMENDATION_LIST_FAILURE:
      return Object.assign({}, state, {
        scriptTableLoad: true
      })

    case actionTypes.PRACTITIONER_ORDERS:
      return Object.assign({}, state, {
        practitionerOrderList: action.payload,
        loaded: true,
      });

    case actionTypes.PATIENT_SCRIPTS:
      return Object.assign({}, state, {
        patientScriptList: action.payload,
        loaded: true,
      });

    case actionTypes.SENT_SCRIPT:
      return Object.assign({}, state, {
        sentScript: action.payload,
        loaded: true,
        editScript: false,
      });

    case actionTypes.EDIT_SCRIPT:
      return Object.assign({}, state, {
        savedVariants: action.payload.script_variants,
        scriptDetails: action.payload,
        loadedScript: true,
        editScript: true,
        scriptId: action.scriptId,
        orderNumber: action.payload.script_order_id,
        scriptName: action.scriptName,
        tenantDiscount: action.tenantDiscount,
        practiceSelfOrder: action.payload.practice_self_order,
        patientId: action.payload.prescribed_to_user_id,
        otherRecommendations: action.payload.other_recommendations,
      });

    case actionTypes.EDIT_SCRIPT_DETAILS:
      return Object.assign({}, state, {
        savedVariants: action.payload.script_variants,
        loadedScript: true,
        editScript: true,
        scriptId: action.scriptId,
        scriptName: action.scriptName,
        practiceSelfOrder: action.payload.practice_self_order,
      });

    case actionTypes.SCRIPT_ORDER_DETAILS:
      return Object.assign({}, state, {
        scriptOrderDetails: action.payload,
        scriptOrderLoaded: true,
      });

    case actionTypes.CHECKOUT_SCRIPT:
      return Object.assign({}, state, {
        scriptId: action.scriptId,
        orderNumber: action.orderNumber,
      });

    case actionTypes.PATIENT_ORDERS:
      return Object.assign({}, state, {
        patientOrderList: action.payload,
        loaded: true,
      });

    case actionTypes.CLEAR_SCRIPT_DETAIL:
      return clearScriptDetail(state);

    case actionTypes.UPDATE_OTHER_RECOMMENDATIONS:
      return Object.assign({}, state, {
        otherRecommendations: action.otherRecommendations,
      });

    case actionTypes.UPDATE_RECOM_REQ:
      return Object.assign({}, state, {
        updateScriptReq: action.update_script_req,
      });
    case actionTypes.SCRIPT_ORDER_UPDATE:
      return Object.assign({}, state, { scriptUpdate: true })

    case script.HAS_SCRIPT_REQUEST:
      return Object.assign({}, state, {
        scriptLoaded: false
      })
    case script.HAS_SCRIPT_SUCCESS:
      return Object.assign({}, state, {
        scriptId: action.payload.script_id || action.payload.draft_script_id,
        scriptLoaded: true,
        currentScriptId: action.payload
      })
    case script.HAS_SCRIPT_FAILURE:
      return Object.assign({}, state, {
        error: action.payload.error,
        scriptLoaded: false,
      })
    case actionTypes.PRO_CREATE_SCRIPT:
      return Object.assign({}, state, {
        scriptId: action.scriptId,
        orderNumber: action.orderNumber,
        tenantDiscount: action.tenantDiscount,
      });

    case actionTypes.RE_ORDER:
      return Object.assign({}, state, {
        newScriptDetails: action.payload,
        orderNumber: action.payload.new_order_number
      });

    case script.GOT_USER_CART_REQUEST:
      return Object.assign({}, state, {
        scriptLoaded: false
      })

    case script.GOT_USER_CART_SUCCESS:
      return Object.assign({}, state, {
        cartId: action.payload.cart_id,
        scriptId: action.payload.script_id,
        scriptLoaded: true,
        currentScriptId: action.payload.script_id
      })

    case script.GOT_USER_CART_FAILURE:
      return Object.assign({}, state, {
        error: action.payload.error,
        scriptLoaded: false,
      })

    case script.UPDATE_CART_SUCCESS:
      return Object.assign({}, state, {
        scriptLoaded: true
      })

    case actionTypes.RECOMMENDED_PRACTITIONER_LIST_INIT:
      return Object.assign({}, state, {
        scriptTableLoad: true
      })

    case actionTypes.RECOMMENDED_PRACTITIONER_LIST:
      return Object.assign({}, state, {
        recommendedPractitionerList: action.payload.scripts,
        scriptsTotalPage: action.payload.total_pages,
        loaded: true,
        scriptTableLoad: false
      });

    case actionTypes.RECOMMENDED_PRACTITIONER_LIST_FAILURE:
      return Object.assign({}, state, {
        scriptTableLoad: true
      })

    case actionTypes.RECOMMENDED_PATIENT_LIST_INIT:
      return Object.assign({}, state, {
        scriptTableLoad: true
      })

    case actionTypes.RECOMMENDED_PATIENT_LIST:
      return Object.assign({}, state, {
        recommendedPatientList: action.payload.scripts,
        scriptsTotalPage: action.payload.total_pages,
        loaded: true,
        scriptTableLoad: false
      });

    case actionTypes.RECOMMENDED_PATIENT_LIST_FAILURE:
      return Object.assign({}, state, {
        scriptTableLoad: true
      })

    case actionTypes.RECOMMENDED_EDIT_SCRIPT:
      return Object.assign({}, state, {
        savedVariants: action.payload.script_variants,
        hcpIngredients: action.payload.hcp_ingredients,
        scriptDetails: action.payload,
        loadedScript: true,
        editScript: true,
        scriptId: action.scriptId,
        orderNumber: action.payload.script_order_id,
        scriptName: action.scriptName,
        tenantDiscount: action.tenantDiscount,
        practiceSelfOrder: action.payload.practice_self_order,
        patientId: action.payload.prescribed_to_user_id,
        scriptDataLoaded: true,
        otherRecommendations: action.payload.other_recommendations,
      });

    case actionTypes.SAVE_HCP_SUPPLEMENTS:
      return Object.assign({}, state, {
        savedHcpIngredients: action.payload,
      });

    default:
      return state;
  }
}

export default rootReducer;
